.center {
  text-align: center;
}

.header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
}

.modal {
  background-color: inherit;
  position: fixed;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 10px;
}

.centered-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.button {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

.button:hover {
  color: #474e5c;
}

.fit-content-width {
  width: fit-content;
}

.flex-box-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;
  }
  
  .monochrome-icon {
    cursor: pointer;
    color: #282c34;
  }
  
  .monochrome-icon:hover {
    color: #474e5c;
  }